import {Calendar} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGrid from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';
import listView from '@fullcalendar/list';

const initializeCalendar = () => {
  const modalElement = document.getElementById('bookingConfirmationModal');
  const modalInstance = new bootstrap.Modal(modalElement);
  const cancelModalElement = document.getElementById('cancelBookingModal');
  const cancelModalInstance = new bootstrap.Modal(cancelModalElement);
  const calendarEl = document.getElementById('fullcalendar');

  // Convert availabilities and bookings to FullCalendar events
  let events = [];
  let availabilities = JSON.parse(calendarEl.getAttribute('data-availabilities'));
  let bookings = JSON.parse(calendarEl.getAttribute('data-bookings'));
  let pricePerHalfHour = parseFloat(calendarEl.getAttribute('data-price')).toFixed(2);
  let bookableGroupId = calendarEl.getAttribute('data-bookable-group-id');
  const businessHoursConfig = availabilitiesToBusinessHours(availabilities);
  let currentCancelHandler = null;
  let currentSelectHandler = null;

  bookings.forEach((booking) => {
    events.push(bookingToEvent(booking));
  });

  const calendar = new Calendar(calendarEl, {
    plugins: [interactionPlugin, timeGrid, dayGrid, listView],
    initialView: 'timeGridWeek',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    select: info => {
      // Remove the previous click handler if it exists
      if (currentSelectHandler) {
        document.getElementById('confirmBookingBtn').removeEventListener('click', currentSelectHandler);
      }

      currentSelectHandler = () => {
        addBooking(info.startStr, info.endStr, bookableGroupId, calendar);
      };

      // Attach the new click handler
      document.getElementById('confirmBookingBtn').addEventListener('click', currentSelectHandler);

      // Set the confirmation text and show the modal
      createConfirmationText(info.startStr, info.endStr, pricePerHalfHour);
      refreshButtons();
      modalInstance.show();
    },
    selectAllow: function (selectInfo) {
      // Parse the selection start and end times
      const start = new Date(selectInfo.startStr);
      const end = new Date(selectInfo.endStr);

      // Check if the selection is within business hours
      return isSelectionWithinBusinessHours(start, end, availabilities);
    },
    eventClick: info => {
      if (currentCancelHandler) {
        // Remove the previous click handler if it exists
        document.getElementById('confirmCancelBtn').removeEventListener('click', currentCancelHandler);
      }

      currentCancelHandler = () => {
        cancelBooking(info.event.id);
        info.event.remove();
        cancelModalInstance.hide();
      };

      // Attach the new click handler and show modal
      document.getElementById('confirmCancelBtn').addEventListener('click', currentCancelHandler);
      cancelModalInstance.show();
      document.getElementById('cancelBookingText').innerHTML = `In case this was a paid booking, a refund will be requested - it may take up to a week to receive it. Do you want to continue this cancellation? <br /><br /> ${info.event.title}`;
    },
    nowIndicator: true,
    slotMinTime: '06:00',
    slotMaxTime: '22:00',
    slotDuration: '00:30:00',
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
    allDaySlot: false,
    selectable: true,
    selectOverlap: false,
    businessHours: businessHoursConfig,
    events: [...events]
  });

  calendar.render();
}
document.addEventListener("turbolinks:load", initializeCalendar);

// Formats date and time to be displayed in the booking modal, by the createConfirmationText function
const splitDateTime = dateTimeStr => {
  const date = new Date(dateTimeStr);

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = `${hours}:${minutes}`;

  const timeZoneOffset = "GMT" + (date.getTimezoneOffset() > 0 ? "-" : "+") +
    Math.abs(date.getTimezoneOffset() / 60).toString().padStart(2, '0');

  return {formattedDate, formattedTime, timeZoneOffset};
}

// Creates confirmation text and change the content of the booking modal
const createConfirmationText = (startDetails, endDetails, pricePerHalfHour) => {
  const start = splitDateTime(startDetails);
  const end = splitDateTime(endDetails);

  let bookingText = `Do you want to book this slot (${start.timeZoneOffset})? <br /><br />
                                Start: ${start.formattedDate} - ${start.formattedTime} <br /> 
                                End: ${end.formattedDate} - ${end.formattedTime}`;


  if (pricePerHalfHour > 0) {
    const price = calculatePriceFromDuration(endDetails, startDetails, pricePerHalfHour);
    bookingText += `<br /><br /> <strong>Price: €${price.toFixed(2)}</strong>`;
  }

  if (document.querySelector('#booking-title')) {
    document.querySelector('#booking-title').value = "";
  }

  document.getElementById('bookingText').innerHTML = bookingText;
}

const refreshButtons = () => {
  document.getElementById('bookingConfirmationCancelBtn').style.display = 'block';
  document.getElementById('confirmBookingBtn').style.display = 'block';

  document.querySelectorAll('button[id^="paymentButton"]')?.forEach(button => button.remove());
  document.querySelector('#pleaseConfirmMethod')?.remove()
}


// Triggered when the user confirms the booking, sends a POST request to the server (/bookings).
// If the booking needs payment: creates a booking with is_active = 0 and shows a button to confirm payment.
// Otherwise, it automatically adds the booking to the calendar.
const addBooking = (startStr, endStr, bookableGroupId, calendar) => {
  let title = document.querySelector('#booking-title')?.value;
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  const dataToSend = {
    booking: {
      start_datetime: startStr,
      end_datetime: endStr,
      bookable_group_id: bookableGroupId,
      title: title
    }
  };

  fetch('/bookings', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify(dataToSend),
  })
    .then(response => response.json())
    .then(data => {
      if (data.status === "success") {
        calendar.addEvent(bookingToEvent(data.booking));
      } else if (data.status === "requires_payment") {
        // Show payment options:
        let buttons = data.booking.buy_buttons.map((button, index) => {
          return `<button id='paymentButton${index}' class='btn btn-primary ms-2'>${button.label}</button>`;
        });
        let cancelBtn = document.getElementById('bookingConfirmationCancelBtn');
        let confirmBtn = document.getElementById('confirmBookingBtn');
        let footerDiv = confirmBtn.parentElement;

        cancelBtn.style.display = 'none';
        footerDiv.insertAdjacentHTML('afterbegin', `<small class='me-2' id="pleaseConfirmMethod">Please confirm your payment method.</small>`)

        buttons.forEach(button => {
          footerDiv.insertAdjacentHTML('beforeend', button)
        });

        confirmBtn.style.display = 'none';

        // Attach event listeners to the buttons
        data.booking.buy_buttons.forEach((button, index) => {
          document.getElementById(`paymentButton${index}`).addEventListener('click', () => {
            confirmPayment(button.link_params);
          });
        });
      }
    })
    .catch(error => console.error('There was an error making the request:', error));
};

// Initiates payment process by sending a POST request to the server (/payments).
const confirmPayment = (linkParams) => {
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  fetch('/payments', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: JSON.stringify(linkParams),
  })
      .then(response => {
        if (!response.ok) {
          return response.json().then(errorData => {
            throw new Error(errorData.error);
          });
        }
        return response.json();
      })
      .then(data => {
        refreshButtons();
        window.location.href = data.payment_url;
      })
      .catch(error => console.error('There was an error making the request.', error.message));
}

// Converts availabilities to business hours format
const availabilitiesToBusinessHours = availabilities => {
  return Object.entries(availabilities).flatMap(([key, val]) => val.map(timeRange => {
    let startTime = moment.parseZone(timeRange.start_time).format('HH:mm');
    let endTime = moment.parseZone(timeRange.end_time).format('HH:mm');
    return {
      daysOfWeek: [parseInt(key)],
      startTime: startTime,
      endTime: endTime
    };
  }));
};

// Checks if the selection is within business hours
const isSelectionWithinBusinessHours = (start, end, availabilities) => {
  let weekday = moment(start).format('d');
  let availability = availabilitiesToBusinessHours(availabilities).filter(availability => availability.daysOfWeek.includes(parseInt(weekday)))[0];

  if (availability) {
    // Date and time of the selection
    let date = moment(start).format('YYYY-MM-DD');
    let startTime = moment(`${date} ${moment(start).format('HH:mm')}`);
    let endTime = moment(`${date} ${moment(end).format('HH:mm')}`);

    // Date and time of the business hours
    let businessStart = moment(`${date} ${availability['startTime']}`);
    let businessEnd = moment(`${date} ${availability['endTime']}`);

    return (
      startTime.isSameOrAfter(businessStart) &&
      endTime.isSameOrBefore(businessEnd) &&
      startTime.isSameOrAfter(moment()) &&
      endTime.isSameOrAfter(moment())
    );
  }
};

// Converts a booking to a FullCalendar event
const bookingToEvent = booking => ({
  id: booking.email_group_id,
  title: booking.title,
  start: booking.start_datetime,
  end: booking.end_datetime,
    color: booking.color
});

// Sends a DELETE request to cancel a booking and removes it from the calendar.
const cancelBooking = (bookingId) => {
  const csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content');

  fetch(`/bookings/${bookingId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
  })
    .then(response => response.json())
    .then(data => {
      if (data.status !== "success") {
        alert('There was an error cancelling the booking.');
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

// Calculates the price of a booking based on the duration and the price per half hour.
const calculatePriceFromDuration = (endDatetime, startDatetime, pricePerHalfHour) => {
  let endTime = new Date(endDatetime);
  let startTime = new Date(startDatetime);
  const differenceInMs = endTime - startTime;
  const halfHours = Math.floor(differenceInMs / (1000 * 60 * 30));
  return pricePerHalfHour * halfHours;
}
