import { Controller } from "stimulus";
import jsQR from "jsqr";

export default class extends Controller {
  static targets = ["response"];

  connect() {
    this.isQRFound = false;
    this.initQRScanner();
  }

  async initQRScanner() {
    const video = this.getVideoElement();
    const { canvas, context } = this.getCanvasElements();
    await this.setupVideoStream(video, canvas, context);
  }

  getVideoElement() {
    return document.getElementById("qr-video");
  }

  getCanvasElements() {
    const canvas = document.getElementById("qr-canvas");
    const context = canvas.getContext("2d", { willReadFrequently: true });
    return { canvas, context };
  }

  async setupVideoStream(video, canvas, context) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } });
      video.srcObject = stream;
      video.play();
      requestAnimationFrame(() => this.tick(video, canvas, context));
    } catch (err) {
      console.error("An error occurred:", err);
      alert("Camera access is required for scanning. Please allow camera access.");
    }
  }

  tick(video, canvas, context) {
    //if (this.isQRFound) return;
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      this.updateCanvasWithVideo(video, canvas, context);
      this.checkForQRCode(canvas, context);
    }
    if (!this.isQRFound) {
      requestAnimationFrame(() => this.tick(video, canvas, context));
    }
  }

  updateCanvasWithVideo(video, canvas, context) {
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  }

  checkForQRCode(canvas, context) {
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    if (code) {
      this.isQRFound = true;
      this.sendQRData(code.data);
    }
  }

  async sendQRData(qrData) {
    const { attendable, eventId, csrfToken } = this.extractPageData();
    if (!csrfToken) {
      console.error("CSRF token not found!");
    }
    const url = `/${attendable}/${eventId}/register`;
    await this.postQRData(url, qrData, csrfToken);
  }

  extractPageData() {
    const urlPath = window.location.pathname;
    const pathSegments = urlPath.split('/');
    const attendable = pathSegments[2];
    const eventId = pathSegments[3];
    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    return {
      attendable,
      eventId,
      csrfToken: csrfTokenMeta ? csrfTokenMeta.content : null
    };
  }

  async postQRData(url, qrData, csrfToken) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
        body: JSON.stringify({ qr_data: qrData }),
      });
      const data = await response.json();
      this.handleQRPostResponse(response, data);
    } catch (e) {
      console.error("Error parsing the response:", e);
    }
  }

  handleQRPostResponse(response, data) {
    if (response.ok) {
      console.log("QR data sent successfully");
    } else {
      console.error("Error sending QR data:", response.statusText);
      return;
    }
    const { status, profile, message, timestamp } = data;
    this.createAlert(status, profile, message, timestamp);
  }

  createAlert(alertType, profile, message, timestamp) {
    if (!alertType || !profile) return;
    let alertElement = document.getElementById('scanned-profile-' + profile);
    if (!alertElement) {
      this.playAlertSound();
      alertElement = this.createAlertElement(alertType, profile, message, timestamp);
      document.getElementById('scan-message-box').prepend(alertElement);
      setTimeout(
          location.reload(), 1000
      )
    }
  }

  playAlertSound() {
    const audio = new Audio('https://upload.wikimedia.org/wikipedia/commons/3/34/Sound_Effect_-_Door_Bell.ogg');
    audio.play();
  }

  createAlertElement(alertType, profile, message, timestamp) {
    const element = document.createElement('div');
    element.classList.add('attendance-record', 'attendance-type-' + alertType);
    element.setAttribute('id', 'scanned-profile-' + profile);
    element.innerHTML = `<span class='attendance-indicator me-1'></span>${timestamp}: ${message}`;
    return element;
  }
}
