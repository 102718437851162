//= require jquery3
//= require select2
//= require popper
//= require bootstrap
//= require dropzone

import "@rails/activestorage";
import "@rails/actioncable";
import "channels";
import "stylesheets/application.scss";
import "@client-side-validations/client-side-validations";
import "controllers";
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';

// Rails UJS & Turbolinks
Rails.start();
Turbolinks.start();

// jQuery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Bootstrap
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

// Lazy load
import "jquery-lazy";

// jQuery UI for sortable
import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/sortable";

// Select2
import Select2 from "select2";
import "select2/dist/css/select2";

// Custom JS
import { remotiPart } from '../custom/jqueryRemotiPart';
import { actions } from '../custom/mentorjamFunctions';
import { documentReady } from '../custom/mentorjamReady';
import { multiselectInput } from '../custom/multiselectInput';
import { addSkillsToProfile } from '../custom/addSkillsToProfile';
import { participantFavouriteFunction } from '../custom/participantFavourite';
import { flashesMsg } from '../custom/flashes';
import { sorting } from '../custom/sorting';
import { stepByStep } from '../custom/stepByStep';
import { addToCalendar } from '../custom/addToCalendar';
import { persistMessages } from '../custom/persistMessages';
import { sidebarForm } from '../custom/sidebarForm';
import { uploadVideo } from '../custom/uploadVideo';
import { sidebarExpand } from '../custom/sidebarExpand';
import { imgRemover } from '../custom/imgRemover';
import { disableButton } from '../custom/disableButton';

// Moment.js
import moment from 'moment';
window.moment = moment;

// Modules
import '../modules/agenda';
import '../modules/calendar';
import '../modules/all_bookings_calendar';
import '../modules/bookable_resource_for_event';

document.addEventListener('turbolinks:load', () => {
  stepByStep();
  remotiPart();
  actions();
  documentReady();
  addToCalendar();
  persistMessages();
  sidebarForm();
  uploadVideo();
  sidebarExpand();
  imgRemover();
  addSkillsToProfile();
  participantFavouriteFunction();
  multiselectInput();
  flashesMsg();
  sorting();
  disableButton();

  // Lazy loading
  $(".lazy").lazy({ delay: 0, bind: 'event' });

  // Bootstrap tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Bootstrap popover
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
});

// Render async
document.addEventListener('render_async_load', function(event) {
  $(".lazy").lazy({ delay: 0, bind: 'event' });
});