import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "unavailable",
    "noFurtherActions",
    "request",
    "connectViaTeams",
    "notOnVar",
    "select",
    "loading",
  ];

  async loadOptions(event) {
    console.log("dataset", event.target.dataset);
    this.updateContent({ status: "loading" });
    const directoryId = event.target.dataset.directoryId;
    const participantId = event.target.dataset.participantId;
    const currentUserId = event.target.dataset.currentUserId;
    const response = await fetch(
      `/member_directories/${directoryId}/connect/${currentUserId}/${participantId}`,
      {
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      },
    );
    const data = await response.json();
    this.updateContent(data);
  }

  updateContent(data) {
    this.hideOtherTargets(this.camelCase(data.status));
    console.log("data", data);
    switch (data.status) {
      case "loading":
        this.loadingTarget.classList.remove("invisible");
      case "unavailable":
        this.unavailableTarget.classList.remove("invisible");
        break;
      case "no_further_actions":
        this.noFurtherActionsTarget.querySelector("p").textContent =
          `${data.title} (${data.label}).`;
        this.noFurtherActionsTarget.querySelector("p").title = data.title;
        this.noFurtherActionsTarget.classList.remove("invisible");
        break;
      case "request":
        this.requestTarget.querySelector("p").textContent =
          "Click on the button below to connect.";
        const requestLink = this.requestTarget.querySelector("a");
        requestLink.href = data.path;
        requestLink.id = data.id;
        requestLink.title = data.title;
        requestLink.classList.remove("invisible");

        requestLink.querySelector("i").className = data.icon;
        requestLink.querySelector("span").textContent = data.label;
        break;
      case "connect_via_teams":
        const connectLink = this.connectViaTeamsTarget.querySelector("a");
        connectLink.href = data.path;
        connectLink.title = data.title;
        connectLink.textContent = data.label;
        connectLink.classList.remove("invisible");
        break;
      case "not_on_var":
        const notOnVarSpan = this.notOnVarTarget.querySelector("span");
        notOnVarSpan.title = data.title;
        notOnVarSpan.textContent = data.label;
        notOnVarSpan.classList.remove("invisible");
        break;
      case "select":
        const select = this.selectTarget.querySelector("select");
        select.innerHTML =
          '<option value="" selected>Select an option</option>';
        data.select.forEach((involvement) => {
          const option = document.createElement("option");
          option.value = involvement.path;
          option.dataset.icon = involvement.relationship_icon;
          option.textContent = `Request ${involvement.relationship_label} with ${involvement.participant_name} as ${involvement.label} in ${involvement.track_title}`;
          select.appendChild(option);
        });
        break;
    }
  }

  hideOtherTargets(currentTargetName) {
    const targetNames = Object.values(this.constructor.targets);

    targetNames.forEach((targetName) => {
      let target = this[`${targetName}Target`];
      let currentTarget = this[`${currentTargetName}Target`];
      if (targetName !== currentTargetName) {
        target.classList.add("d-none");
      } else {
        currentTarget.classList.remove("d-none");
        currentTarget.classList.remove("invisible");
      }
    });
  }

  requestMatch(event) {
    const path = event.target.value;
    if (path) {
      window.location.href = path;
    }
  }

  camelCase(str) {
    return str.replace(/_([a-z])/g, function (g) {
      return g[1].toUpperCase();
    });
  }
}
